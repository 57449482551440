import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { Recipe, RecipeApi } from '@app/_api';
import { EMPTY, catchError } from 'rxjs';

export const recipeResolver: ResolveFn<Recipe> = (
  route: ActivatedRouteSnapshot,
) => {
  const router = inject(Router);

  return inject(RecipeApi)
    .findOneById(Number(route.paramMap.get('id')))
    .pipe(
      catchError(() => {
        router.navigate(['/']);

        return EMPTY;
      }),
    );
};
