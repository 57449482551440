export interface Credentials {
  email: string;
  password: string;
}

export interface IUser {
  id: number;
  email: string;
  password?: string;
}

export class User {
  id: number;
  email: string;
  password?: string;

  get name(): string {
    return this.email.split('@')[0];
  }

  constructor(user: IUser) {
    this.id = user.id;
    this.email = user.email;
    this.password = user.password;
  }
}
