<div class="row g-4">
  <div class="col-12">
    <h2 class="text-center mb-0">Delete {{ name }}</h2>
  </div>

  <div class="col-12">
    <button
      type="button"
      class="w-100 btn btn-outline-danger"
      (click)="delete()"
    >
      Delete
    </button>
  </div>
</div>
