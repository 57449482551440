@if (user) {
  <nav class="navbar bg-black navbar-dark">
    <div class="w-100 navbar-nav flex-row justify-content-center">
      <ng-content></ng-content>

      <div class="nav-link mx-3">|</div>

      <div class="d-none d-sm-flex">
        <a [routerLink]="['/']" class="nav-link mx-2">recipes</a>
        <a [routerLink]="['/ingredients']" class="nav-link mx-2">ingredients</a>
        <a [routerLink]="['/users']" class="nav-link mx-2">users</a>
      </div>

      <div class="d-none d-sm-block nav-link mx-3">|</div>

      <a role="button" (click)="logout()" class="nav-link mx-2">log out</a>
    </div>
  </nav>
}
