export interface IRecipe {
  id: number;
  name: string;
  amount: string;
  minutes: number;
  ingredients: string;
  instructions: string;
}

export class Recipe {
  id: number;
  name: string;
  amount: string;
  minutes: number;
  ingredients: string;
  instructions: string;

  constructor(recipe: IRecipe) {
    this.id = recipe.id;
    this.name = recipe.name;
    this.amount = recipe.amount;
    this.minutes = recipe.minutes;
    this.ingredients = recipe.ingredients;
    this.instructions = recipe.instructions;
  }
}
