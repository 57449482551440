<app-admin>
  <a role="button" (click)="create()" class="nav-link mx-2">create</a>
</app-admin>

<div class="w-100 d-flex justify-content-center pt-4">
  <div [style.width.px]="300">
    <div class="bg-light polygon-3 p-4">
      <div class="bg-black polygon-3 polygon-small p-1">
        <input
          type="text"
          class="form-control bg-black text-white border-0 text-center"
          [(ngModel)]="search"
          placeholder="Search"
        />
      </div>
    </div>
  </div>
</div>

<div class="w-100 d-flex flex-wrap justify-content-center py-4">
  @for (recipe of filteredRecipes(); track recipe.id) {
    <a
      class="position-relative m-4 text-black"
      [style.width.px]="300"
      [style.height.px]="200"
      [routerLink]="[recipe.id]"
    >
      <div
        class="w-75 h-75 position-absolute-center bg-light polygon-{{
          recipe.id % 8
        }}"
      ></div>

      <app-ingredients-image
        class="w-100 h-100 position-absolute-center"
        [recipe]="recipe"
        [ingredients]="images()"
      ></app-ingredients-image>

      <div
        class="position-absolute-center text-center bg-black text-white p-3 polygon-{{
          recipe.id % 8
        }} polygon-small"
      >
        <b>{{ recipe.name }} <br /></b>
        {{ recipe.minutes }} minutes
      </div>
    </a>
  } @empty {
    No recipes found...
  }
</div>
