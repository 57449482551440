import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  standalone: true,
})
export class DeleteModalComponent {
  private readonly modal = inject(NgbActiveModal);

  name = '';

  delete(): void {
    this.modal.close();
  }
}
