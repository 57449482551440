<form [formGroup]="form" (submit)="save()" class="row g-4">
  <div class="col-12">
    <h2 class="text-center mb-0">
      {{ form.value.id != null ? 'Edit ingredient' : 'Create ingredient' }}
    </h2>
  </div>

  <div class="col-6">
    <div class="form-group">
      <label for="names" class="required">Names</label>
      <textarea
        class="form-control"
        formControlName="names"
        name="names"
        id="names"
        rows="4"
        #names="formControl"
      ></textarea>
      @if (names.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
      @if (names.errors?.['maxlength']) {
        <span class="invalid-feedback">
          This field can only contain 255 characters.
        </span>
      }
      <span class="form-text">
        You can place multiple names on separate lines.
      </span>
    </div>

    <div class="form-group">
      <label for="color" class="required">Color</label>
      <input
        type="color"
        class="form-control"
        formControlName="color"
        name="color"
        id="color"
        #color="formControl"
        [style.height.px]="40"
      />
      @if (color.errors?.['required']) {
        <span class="invalid-feedback">This field is required.</span>
      }
    </div>

    <div class="form-group">
      <label for="svg" class="w-100">
        Vector

        <div class="float-end cursor-pointer" (click)="fileInput.click()">
          upload
        </div>

        <input
          #fileInput
          name="file"
          type="file"
          accept=".svg"
          hidden
          (change)="upload($any($event.target).files)"
        />
      </label>
      <textarea
        class="form-control font-monospace"
        formControlName="svg"
        name="svg"
        id="svg"
        rows="4"
        #svg="formControl"
      ></textarea>
      <span class="form-text">
        Thi field may contain the <code>d</code> attribute of a
        <code>path</code> tag inside an <code>svg</code> that has a
        <code>viewBox</code> of <code>0 0 100 100</code>.
      </span>
    </div>
  </div>

  <div class="col-6">
    <div
      class="w-100 h-100 position-relative d-flex justify-content-center align-items-center"
    >
      <div
        class="position-absolute bg-light pointer-events-auto"
        [style.width.px]="300"
        [style.height.px]="300"
      ></div>

      <app-ingredient-image
        class="w-100 h-100 position-absolute"
        [svg]="svg.value"
        [color]="color.value"
      ></app-ingredient-image>
    </div>
  </div>

  <div class="col-12">
    <div class="row g-4">
      @if (form.value.id) {
        <div class="col">
          <button
            type="button"
            class="w-100 btn btn-outline-danger"
            (click)="delete()"
          >
            Delete
          </button>
        </div>
      }

      <div class="col">
        <button
          type="submit"
          class="w-100 btn"
          [class.btn-outline-primary]="!error"
          [class.btn-outline-danger]="error"
          [disabled]="form.invalid || form.pristine"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</form>
