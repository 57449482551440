import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { User } from '@app/_api';
import { UserApi } from '@app/_api/apis/user.api';
import { EMPTY, catchError } from 'rxjs';

export const usersResolver: ResolveFn<User[]> = () => {
  const router = inject(Router);

  return inject(UserApi)
    .find()
    .pipe(
      catchError(() => {
        router.navigate(['/']);

        return EMPTY;
      }),
    );
};
