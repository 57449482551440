import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Credentials, IUser, User } from '../entities';
import { API_BASE_URL } from '../provider';
import { toClass, toClasses } from '../utils';

@Injectable()
export class UserApi {
  private readonly baseUrl = inject(API_BASE_URL);
  private readonly http = inject(HttpClient);

  login(credentials: Credentials): Observable<string> {
    return this.http
      .post<{ jwt: string }>(`${this.baseUrl}/login`, credentials)
      .pipe(map(jwt => jwt.jwt));
  }

  find(): Observable<User[]> {
    return this.http
      .get<IUser[]>(`${this.baseUrl}/users`)
      .pipe(toClasses(User));
  }

  findOneById(id: number): Observable<User> {
    return this.http
      .get<IUser>(`${this.baseUrl}/users/${id}`)
      .pipe(toClass(User));
  }

  saveOne(user: Partial<User>): Observable<User> {
    return this.http
      .post<User>(`${this.baseUrl}/users`, user)
      .pipe(toClass(User));
  }

  deleteOneById(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/users/${id}`);
  }
}
