import { Directive, Self } from '@angular/core';
import { FormArrayName, FormControlName, FormGroupName } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filler: any = null;

@Directive({
  selector: '[formControlName]',
  standalone: true,
  exportAs: 'formControl',
})
export class FormControlNameExportDirective extends FormControlName {
  constructor(@Self() formControlName: FormControlName) {
    super(filler, filler, filler, filler, filler);

    return formControlName;
  }
}

@Directive({
  selector: '[formGroupName]',
  standalone: true,
  exportAs: 'formGroup',
})
export class FormGroupNameExportDirective extends FormGroupName {
  constructor(@Self() formGroupName: FormGroupName) {
    super(filler, filler, filler);

    return formGroupName;
  }
}

@Directive({
  selector: '[formArrayName]',
  standalone: true,
  exportAs: 'formArray',
})
export class FormArrayNameExportDirective extends FormArrayName {
  constructor(@Self() formArrayName: FormArrayName) {
    super(filler, filler, filler);

    return formArrayName;
  }
}
