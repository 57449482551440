import { Component, inject, input, model } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Ingredient, Recipe, RecipeApi } from '@app/_api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { AdminComponent } from '../admin/admin.component';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { IngredientsImageComponent } from '../ingredients-image/ingredients-image.component';
import { RecipeModalComponent } from '../recipe-modal/recipe-modal.component';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  standalone: true,
  imports: [RouterLink, AdminComponent, IngredientsImageComponent],
})
export class RecipeComponent {
  private readonly recipeApi = inject(RecipeApi);
  private readonly router = inject(Router);
  private readonly modalService = inject(NgbModal);

  readonly recipe = model.required<Recipe>();
  readonly images = input.required<Record<string, Ingredient>>();

  edit(): void {
    const modal = this.modalService.open(RecipeModalComponent, { size: 'lg' });
    const component: RecipeModalComponent = modal.componentInstance;

    component.form.patchValue(this.recipe());

    modal.result
      .then(recipe => {
        this.recipe.set(recipe);
      })
      .catch(noop);
  }

  delete(): void {
    const modal = this.modalService.open(DeleteModalComponent);
    const component: DeleteModalComponent = modal.componentInstance;

    component.name = 'recipe';

    modal.result
      .then(() => {
        this.recipeApi.deleteOneById(this.recipe().id).subscribe(() => {
          this.router.navigate(['/']);
        });
      })
      .catch(noop);
  }
}
