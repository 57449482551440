import { Routes } from '@angular/router';
import { IngredientsComponent } from './components/ingredients/ingredients.component';
import { RecipeComponent } from './components/recipe/recipe.component';
import { RecipesComponent } from './components/recipes/recipes.component';
import { UsersComponent } from './components/users/users.component';
import { authGuard } from './guards';
import {
  imagesResolver,
  ingredientsResolver,
  recipeResolver,
  recipesResolver,
  usersResolver,
} from './resolvers';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: RecipesComponent,
    resolve: { recipes: recipesResolver, images: imagesResolver },
  },
  {
    path: 'users',
    component: UsersComponent,
    resolve: { users: usersResolver, ingredients: ingredientsResolver },
    canActivate: [authGuard],
  },
  {
    path: 'ingredients',
    component: IngredientsComponent,
    resolve: { ingredients: ingredientsResolver },
    canActivate: [authGuard],
  },
  {
    path: ':id',
    component: RecipeComponent,
    resolve: { recipe: recipeResolver, images: imagesResolver },
  },
  { path: '**', redirectTo: '' },
];
