const _distribute = <A, B>(
  xs: A[],
  ys: B[],
  count = Math.round(ys.length / (xs.length + 1)),
): (A | B)[] =>
  xs.length == 0
    ? [...ys]
    : [
        ...ys.slice(0, count),
        xs[0],
        ..._distribute(xs.slice(1), ys.slice(count)),
      ];

export const distribute = <T>(x: T[], dividor: (a: T) => boolean): T[] => {
  const a = x.filter(x => dividor(x));
  const b = x.filter(x => !dividor(x));

  return a.length > b.length ? _distribute(b, a) : _distribute(a, b);
};
