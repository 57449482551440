import { map, OperatorFunction } from 'rxjs';

export function toClass<T, R>(
  Class: new (instance: T) => R,
): OperatorFunction<T, R> {
  return map(x => new Class(x));
}

export function toClasses<T, R>(
  Class: new (instance: T) => R,
): OperatorFunction<T[], R[]> {
  return map((x: T[]) => x.map(x => new Class(x)));
}
