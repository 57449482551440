import { Injectable, inject } from '@angular/core';
import { Credentials } from '@app/_api';
import { UserApi } from '@app/_api/apis/user.api';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly jwtHelper = inject(JwtHelperService);
  private readonly userApi = inject(UserApi);

  user?: string;

  private interval?: ReturnType<typeof setTimeout>;

  constructor() {
    this.start();
  }

  login(credentials: Credentials): Observable<void> {
    clearInterval(this.interval);

    return this.userApi.login(credentials).pipe(
      map(token => {
        localStorage.setItem('token', token);

        this.start();
      }),
    );
  }

  logout(): void {
    clearInterval(this.interval);

    this.user = undefined;

    localStorage.removeItem('token');
  }

  start(): void {
    clearInterval(this.interval);

    if (!this.jwtHelper.isTokenExpired()) {
      this.user = this.jwtHelper.decodeToken().email;

      this.interval = setTimeout(() => {
        this.start();
      }, 1e4);
    } else {
      localStorage.removeItem('token');

      this.user = undefined;
    }
  }
}
