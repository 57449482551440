<app-admin>
  <a role="button" (click)="create()" class="nav-link mx-2">create</a>
  <a role="button" (click)="missing()" class="nav-link mx-2">missing</a>
</app-admin>

<div class="w-100 d-flex flex-wrap justify-content-center py-4">
  @for (ingredient of ingredients(); track ingredient.id) {
    <a
      role="button"
      class="position-relative m-4 text-black"
      [style.width.px]="300"
      [style.height.px]="200"
      (click)="edit(ingredient)"
    >
      <div
        class="w-75 h-75 position-absolute-center bg-light polygon-{{
          ingredient.id % 8
        }}"
      ></div>

      <app-ingredient-image
        class="w-100 h-100 position-absolute-center"
        [svg]="ingredient.svg"
        [color]="ingredient.color"
      ></app-ingredient-image>

      <div
        class="position-absolute-center bg-black text-white p-3 polygon-{{
          ingredient.id % 8
        }} polygon-small"
      >
        <b>{{ ingredient.name }}</b>
      </div>
    </a>
  } @empty {
    No ingredients found...
  }
</div>
