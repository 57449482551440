<svg
  [attr.viewBox]="C - W + ' ' + (C - H) + ' ' + 2 * W + ' ' + 2 * H"
  class="w-100 h-100"
>
  @for (image of images(); track image.id) {
    <g
      [attr.transform]="
        'scale(0.25) translate(' +
        2.5 * W * image.x +
        ' ' +
        2.5 * H * image.y +
        ') rotate(' +
        image.th +
        ')'
      "
      [attr.transform-origin]="C + ' ' + C"
    >
      @if (image.svg) {
        <path
          [attr.d]="image.svg"
          [attr.fill]="image.color"
          stroke="white"
          stroke-width="2"
        ></path>
      } @else {
        @for (
          c of [
            [20, 40],
            [30, 80],
            [40, 60],
            [50, 20],
            [60, 40],
            [70, 70],
            [80, 30]
          ];
          track $index
        ) {
          <circle
            [attr.cx]="c[0]"
            [attr.cy]="c[1]"
            r="8"
            [attr.fill]="image.color"
            stroke="white"
            stroke-width="2"
          />
        }
      }
    </g>
  }
</svg>
