import {
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Ingredient, Recipe } from '@app/_api';
import { simplify } from '@app/functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { AdminComponent } from '../admin/admin.component';
import { IngredientsImageComponent } from '../ingredients-image/ingredients-image.component';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { RecipeModalComponent } from '../recipe-modal/recipe-modal.component';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  standalone: true,
  imports: [
    AdminComponent,
    IngredientsImageComponent,
    FormsModule,
    RouterLink,
    RouterLinkActive,
  ],
})
export class RecipesComponent {
  private readonly router = inject(Router);
  private readonly modalService = inject(NgbModal);

  readonly recipes = input.required<Recipe[]>();
  readonly images = input.required<Record<string, Ingredient>>();

  readonly search = signal('');

  readonly filteredRecipes = computed(() => {
    const search = simplify(this.search());

    return this.recipes().filter(recipe =>
      simplify(recipe.name + '\n' + recipe.ingredients).includes(search),
    );
  });

  constructor() {
    effect(
      () => {
        if (simplify(this.search()) === 'login') {
          this.search.set('');

          this.modalService.open(LoginModalComponent);
        }
      },
      { allowSignalWrites: true },
    );
  }

  create(): void {
    const modal = this.modalService.open(RecipeModalComponent, { size: 'lg' });

    modal.result
      .then(recipe => {
        this.router.navigate([recipe.id]);
      })
      .catch(noop);
  }
}
