<app-admin>
  <a role="button" (click)="edit()" class="nav-link mx-2">edit</a>
</app-admin>

<div class="w-100 d-flex justify-content-center pt-4">
  <a
    class="position-relative m-4 text-black"
    [style.width.px]="300"
    [style.height.px]="200"
    [routerLink]="['/']"
  >
    <div
      class="w-75 h-75 position-absolute-center bg-light polygon-{{
        recipe().id % 8
      }}"
    ></div>

    <app-ingredients-image
      class="w-100 h-100 position-absolute-center"
      [recipe]="recipe()"
      [ingredients]="images()"
    ></app-ingredients-image>

    <div
      class="position-absolute-center text-center bg-black text-white p-3 polygon-{{
        recipe().id % 8
      }} polygon-small"
    >
      <b>{{ recipe().name }} <br /></b>
      {{ recipe().minutes }} minutes <br />
      {{ recipe().amount }}
    </div>
  </a>
</div>

<div class="w-100 d-flex flex-wrap justify-content-center">
  <div
    class="m-4 p-5 bg-light text-lg-end white-space-pre-line polygon-{{
      recipe().id % 8
    }}"
    [style.width.px]="400"
  >
    {{ recipe().ingredients }}
  </div>
  <div
    class="m-4 p-5 bg-light white-space-pre-line polygon-{{
      (recipe().id + 4) % 8
    }}"
    [style.width.px]="400"
  >
    {{ recipe().instructions }}
  </div>
</div>
