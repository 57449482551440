<svg viewBox="0 0 100 100" class="w-100 h-100">
  @if (svg()) {
    <path
      [attr.d]="svg()"
      [attr.fill]="color()"
      stroke="white"
      stroke-width="2"
    ></path>
  } @else {
    @for (
      c of [
        [80, 30],
        [20, 40],
        [70, 70],
        [30, 80],
        [50, 20],
        [60, 40],
        [40, 60]
      ];
      track $index
    ) {
      <circle
        [attr.cx]="c[0]"
        [attr.cy]="c[1]"
        r="8"
        [attr.fill]="color()"
        stroke="white"
        stroke-width="2"
      />
    }
  }
</svg>
