import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Ingredient, IngredientApi } from '@app/_api';
import { simplify } from '@app/functions';
import { map } from 'rxjs';

export const imagesResolver: ResolveFn<Record<string, Ingredient>> = () =>
  inject(IngredientApi)
    .find()
    .pipe(
      map(ingredients => {
        const images: Record<string, Ingredient> = {};

        for (const ingredient of ingredients) {
          for (const name of ingredient.names.split('\n')) {
            images[simplify(name)] = ingredient;
          }
        }

        return images;
      }),
    );
