<div class="row g-4">
  <div class="col-12">
    <h2 class="text-center mb-0">Missing ingredients</h2>
  </div>

  <div class="col-12">
    <div class="d-flex flex-wrap justify-content-center">
      @for (name of names; track name) {
        <a
          role="button"
          class="position-relative m-4 text-black"
          [style.width.px]="250"
          [style.height.px]="150"
          (click)="create(name)"
        >
          <div
            class="w-75 h-75 position-absolute-center bg-light polygon-{{
              $index % 8
            }}"
          ></div>

          <div
            class="position-absolute-center text-center bg-black text-white p-3 polygon-{{
              $index % 8
            }} polygon-small"
          >
            <b>{{ name }}</b>
          </div>
        </a>
      }
    </div>
  </div>
</div>
