import { Component, inject, model } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Ingredient } from '@app/_api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { AdminComponent } from '../admin/admin.component';
import { IngredientImageComponent } from '../ingredient-image/ingredient-image.component';
import { IngredientModalComponent } from '../ingredient-modal/ingredient-modal.component';
import { MissingIngredientsModalComponent } from '../missing-ingredients-modal/missing-ingredients-modal.component';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  standalone: true,
  imports: [AdminComponent, RouterLink, IngredientImageComponent],
})
export class IngredientsComponent {
  private readonly modalService = inject(NgbModal);

  readonly ingredients = model.required<Ingredient[]>();

  create(): void {
    const modal = this.modalService.open(IngredientModalComponent, {
      size: 'lg',
    });

    modal.result
      .then(ingredient => {
        this.ingredients.update(ingredients => [...ingredients, ingredient]);
      })
      .catch(noop);
  }

  missing(): void {
    const modal = this.modalService.open(MissingIngredientsModalComponent, {
      size: 'xl',
    });
    const component: MissingIngredientsModalComponent = modal.componentInstance;

    component.ingredients = this.ingredients();

    modal.result.finally(() => {
      this.ingredients.update(ingredients => [...ingredients].sort());
    });
  }

  edit(ingredient: Ingredient): void {
    const modal = this.modalService.open(IngredientModalComponent, {
      size: 'lg',
    });
    const component: IngredientModalComponent = modal.componentInstance;

    component.form.patchValue(ingredient);

    modal.result
      .then((i?: Ingredient) => {
        this.ingredients.update(ingredients => {
          if (i) {
            const index = ingredients.findIndex(x => x.id === i.id);

            ingredients[index] = i;

            return [...ingredients];
          } else {
            return ingredients.filter(x => x.id !== ingredient.id);
          }
        });
      })
      .catch(noop);
  }
}
