import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRecipe, Recipe } from '../entities';
import { API_BASE_URL } from '../provider';
import { toClass, toClasses } from '../utils';

@Injectable()
export class RecipeApi {
  private readonly baseUrl = inject(API_BASE_URL);
  private readonly http = inject(HttpClient);

  find(): Observable<Recipe[]> {
    return this.http
      .get<IRecipe[]>(`${this.baseUrl}/recipes`)
      .pipe(toClasses(Recipe));
  }

  findOneById(id: number): Observable<Recipe> {
    return this.http
      .get<IRecipe>(`${this.baseUrl}/recipes/${id}`)
      .pipe(toClass(Recipe));
  }

  saveOne(recipe: Partial<Recipe>): Observable<Recipe> {
    return this.http
      .post<Recipe>(`${this.baseUrl}/recipes`, recipe)
      .pipe(toClass(Recipe));
  }

  deleteOneById(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/recipes/${id}`);
  }
}
