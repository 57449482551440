import { InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { IngredientApi, RecipeApi } from './apis';
import { UserApi } from './apis/user.api';

export const API_BASE_URL = new InjectionToken<string>('API base URL');

export function provideApi(baseUrl: string) {
  return makeEnvironmentProviders([
    {
      provide: API_BASE_URL,
      useValue: baseUrl,
    },
    RecipeApi,
    IngredientApi,
    UserApi,
  ]);
}
