export interface IIngredient {
  id: number;
  names: string;
  color: string;
  svg: string;
}

export class Ingredient {
  id: number;
  names: string;
  color: string;
  svg: string;

  get name(): string {
    return this.names.split('\n')[0].trim();
  }

  constructor(ingredient: IIngredient) {
    this.id = ingredient.id;
    this.names = ingredient.names;
    this.color = ingredient.color;
    this.svg = ingredient.svg;
  }
}
