import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IIngredient, Ingredient } from '../entities';
import { API_BASE_URL } from '../provider';
import { toClass, toClasses } from '../utils';

@Injectable()
export class IngredientApi {
  private readonly baseUrl = inject(API_BASE_URL);
  private readonly http = inject(HttpClient);

  find(): Observable<Ingredient[]> {
    return this.http
      .get<IIngredient[]>(`${this.baseUrl}/ingredients`)
      .pipe(toClasses(Ingredient));
  }

  findOneById(id: number): Observable<Ingredient> {
    return this.http
      .get<IIngredient>(`${this.baseUrl}/ingredients/${id}`)
      .pipe(toClass(Ingredient));
  }

  saveOne(ingredient: Partial<Ingredient>): Observable<Ingredient> {
    return this.http
      .post<Ingredient>(`${this.baseUrl}/ingredients`, ingredient)
      .pipe(toClass(Ingredient));
  }

  deleteOneById(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/ingredients/${id}`);
  }
}
